import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthCallbackComponent} from './components/auth-callback/auth-callback.component';
// import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import {DefaultLayoutComponent} from './containers/default-layout';
import {HomeComponent} from './pages/home/home.component';
import {AuthGuardService} from './services/auth-guard.service';

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      // { path: 'login', loadChildren: () => import('../app/core/login/login.module').then(m => m.LoginModule) },
      {path: 'auth-callback', component: AuthCallbackComponent},
      {
        path: '', component: DefaultLayoutComponent,
        children: [
          {
            path: 'home', component: HomeComponent,
          },
          {
            path: 'quan-ly',
            loadChildren: () => import('../app/pages/nguoi-dung/nguoi-dung.module').then(m => m.NguoiDungModule)
          },
          {
            path: 'setting-role',
            loadChildren: () => import('../app/pages/cai-dat-quyen/cai-dat-quyen.module').then(m => m.CaiDatQuyenModule)
          },
          {
            path: 'cau-hinh',
            loadChildren: () => import('../app/pages/cau-hinh/cau-hinh.module').then(m => m.CauHinhQuyenModule)
          }
          // { path: 'customer-management-system', loadChildren: () => import('../app/components/customer-management-system/customer-management-system.module').then(m => m.CustomerManagementSystemModule) },
          // { path: 'early-warning-system', loadChildren: () => import('../app/components/early-warning-system/early-warning-system.module').then(m => m.EarlyWarningSystemModule) },
          // { path: 'control-system', loadChildren: () => import('../app/components/control-system/control-system.module').then(m => m.ControlSystemModule) },
          // { path: 'financial-control-system', loadChildren: () => import('../app/components/financial-control-system/financial-control-system.module').then(m => m.FinancialControlSystemModule) },
          // { path: 'supplier-management-system', loadChildren: () => import('../app/components/supplier-management-system/supplier-management-system.module').then(m => m.SupplierManagementSystemModule) },

        ], canActivate: [
          AuthGuardService
        ]
      },
      // { path: 'auth', loadChildren: () => import('./demo/components/auth/auth.module').then(m => m.AuthModule) },
      // { path: 'landing', loadChildren: () => import('./demo/components/landing/landing.module').then(m => m.LandingModule) },
      // { path: 'notfound', component: NotfoundComponent },
      // { path: '**', redirectTo: '/notfound' },
    ])
    // ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

