import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {AuthService} from './auth.service';
import {catchError, map, Observable, of, throwError} from 'rxjs';
import { AppConfigService } from 'src/config/app-config.service';


@Injectable()
export class ApiService {
  options = {};
  apiConfigBaseUrl = '';
  constructor(
    private http: HttpClient,
    private _environment: AppConfigService,
    private authService: AuthService) {
    this.apiConfigBaseUrl = environment.apiCoreBase;
    this.options = {
      headers: new HttpHeaders({
        Authorization: this.authService.getAuthorizationHeaderValue(),
        'Content-Type': 'application/json',
      })
    };
  }

  // End
  getRegSaleInfo(): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/coresaler/GetRegSaleInfo`);
  }

  // UserManage

  
  getManagerFilter(): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/usermanage/GetManagerFilter`, this.options);
  }

  getManagerPage(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/usermanage/GetManagerPage?` + queryParams, this.options);
  }

  getManagerInfo(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/usermanage/GetManagerInfo?` + queryParams, this.options);
  }

  setManagerCreate(queryParams): Observable<any> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/usermanage/SetManagerCreate`, queryParams, this.options);
  }

  setManagerInfo(queryParams): Observable<any> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/usermanage/SetManagerInfo`, queryParams, this.options);
  }

  setManagerSubmit(queryParams): Observable<any> {
    return this.http.put(`${this.apiConfigBaseUrl}/api/v1/usermanage/SetManagerSubmit`, queryParams, this.options);
  }

  getManagerList(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/usermanage/GetManagerList?` + queryParams, this.options);
  }

  delManagerInfo(queryParams): Observable<any> {
    return this.http.delete(`${this.apiConfigBaseUrl}/api/v1/usermanage/DelManagerInfo?` + queryParams, this.options);
  }

  setManagerClose(queryParams): Observable<any> {
    return this.http.delete(`${this.apiConfigBaseUrl}/api/v1/usermanage/SetManagerClose?` + queryParams, this.options);
  }

  // Workflow

  
  getWorkflowFilter(): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/workflow/GetWorkflowFilter`, this.options);
}


  getWorkflowPage(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/workflow/GetWorkflowPage?` + queryParams, this.options);
  }

  getWorkflowInfo(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/workflow/GetWorkflowInfo?` + queryParams, this.options);
  }

  setWorkSubmitAdmin(queryParams): Observable<any> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/workflow/SetWorkSubmit`, queryParams, this.options);
  }

  setWorkApprove(queryParams): Observable<any> {
    return this.http.put(`${this.apiConfigBaseUrl}/api/v1/workflow/SetWorkApprove`, queryParams, this.options);
  }


  // UniUser

  getUserProfile(): Observable<any> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/UniUser/GetUserProfile`, this.options);
  }

  // WebMenu

  getWebMenuPageByWebId(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/webmenu/GetWebMenuPageByWebId?` + queryParams, this.options);
  }

  getWebMenuTree(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/webmanager/GetWebMenuTree?` + queryParams, this.options);
  }

  getClientMenuInfo(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/webmenu/GetClientMenuInfo?` + queryParams, this.options);
  }

  setClientMenuInfo(queryParams): Observable<any> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/webmenu/SetClientMenuInfo`, queryParams, this.options);
  }

  setClientRoleMenu(queryParams): Observable<any> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/webrole/SetClientRoleMenu`, queryParams, this.options);
  }

  setMenuAction(queryParams): Observable<any> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/webmenu/SetMenuAction`, queryParams, this.options);
  }

  delClientMenu(queryParams): Observable<any> {
    return this.http.delete(`${this.apiConfigBaseUrl}/api/v1/webmenu/DelClientMenu?` + queryParams, this.options);
  }

  delMenuAction(queryParams): Observable<any> {
    return this.http.delete(`${this.apiConfigBaseUrl}/api/v1/webmenu/DelMenuAction?` + queryParams, this.options);
  }

  delClientWeb(queryParams) {
    return this.http.delete(`${this.apiConfigBaseUrl}/api/v1/webmanager/DelClientWeb?` + queryParams, this.options);
  }

  // WebAction
  getClientActionListByWebId(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/webaction/GetWebActionsByWebId?` + queryParams, this.options);
  }

  setClientAction(queryParams): Observable<any> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/webaction/SetClientAction`, queryParams, this.options);
  }

  delClientAction(queryParams): Observable<any> {
    return this.http.delete(`${this.apiConfigBaseUrl}/api/v1/webAction/DelClientAction?` + queryParams, this.options);
  }

  // WebRole
  getWebRolePageByWebId(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/webrole/GetWebRolePageByWebId?` + queryParams, this.options);
  }

  getClientRoleInfo(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/webrole/GetClientRoleInfo?` + queryParams, this.options);
  }

  getWebRoleMenuTree(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/webmanager/GetWebRoleMenuTree?` + queryParams, this.options);
  }

  getClientWebRoleList(queryParams = ''): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/webrole/GetClientWebRoleList?` + queryParams, this.options);
  }

  setClientRoleInfo(queryParams): Observable<any> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/webrole/SetClientRoleInfo`, queryParams, this.options);
  }

  delClientRole(queryParams): Observable<any> {
    return this.http.delete(`${this.apiConfigBaseUrl}/api/v1/webrole/DelClientRole?` + queryParams, this.options);
  }

  // Phần quyền web

  getClientWebPage(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/webmanager/GetClientWebPage?` + queryParams, this.options);
  }

  getClientWebList(): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/webmanager/GetClientWebList`, this.options);
  }

  getClientWebInfoManager(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/webmanager/GetClientWebInfo?` + queryParams, this.options);
  }

  setClientWebInfoManager(queryParams): Observable<any> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/webmanager/SetClientWebInfo`, queryParams, this.options);
  }

  getListMenuByUserId(userId, webId): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/webmanager/ClientMenuGetListByUserId?` +
      `userId=${userId}&webId=${webId}`, this.options);
  }

  getAccountListCore(queryParams): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/coreaccount/getAccountList?` + queryParams, this.options);
  }

  getObjectList(queryParams): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/workflow/GetObjectList?` + queryParams, this.options);
  }

  getColumnObjectByLink(url): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}` + url, this.options);
  }


  getKhachHang(queryParams, type): Observable<any> {
    if (type == 0) {
      return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/individual/GetCustIndiPage?` + queryParams, this.options);
    } else {
      return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/coporate/GetCustCopoPage?` + queryParams, this.options);
    }
  }

  setWorkSubmit(params): Observable<any> {
    return this.http.post<any>(`${this.apiConfigBaseUrl}/api/v1/customer/SetWorkSubmit`, params, this.options);
  }

  removeCustProfile(queryParams): Observable<any> {
    return this.http
      .delete<any>(`${this.apiConfigBaseUrl}/api/v1/customer/RemoveCustProfile?` + queryParams, this.options);
  }

  searchCustomer(queryParams): Observable<any> {
    return this.http
      .get<any>(`${this.apiConfigBaseUrl}/api/v1/customer/GetCustIndividualPage?` + queryParams, this.options);
  }

  setCustIndiIdentityCreate(params): Observable<any> {
    return this.http.post<any>(`${this.apiConfigBaseUrl}/api/v1/individual/SetCustIndiIdentityCreate`, params, this.options);
  }

  getCustIndiCreate(params): Observable<any> {
    return this.http.post<any>(`${this.apiConfigBaseUrl}/api/v1/individual/GetCustIndiCreate`, params, this.options);
  }

  getBankList(): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/coreaccount/GetBankList`, this.options);
  }

  setCustIndiCreate(params): Observable<any> {
    return this.http.put<any>(`${this.apiConfigBaseUrl}/api/v1/individual/SetCustIndiCreate`, params, this.options);
  }

  getCustIndiFields(queryParams): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/individual/GetCustIndiFields?` + queryParams, this.options);
  }

  getIdentityCardInfomation(image: File) {
    const options = {
      headers: new HttpHeaders({
        'key': 'WZWfWCN2VPDxbYsV6sRfR0N1fV8x030h'
      })
    };
    const formdata = new FormData();
    formdata.append('image', image, 'TanTano');
    formdata.append('request_id', '14071996');
    return this.http.post('https://api.cloudekyc.com/v3.2/ocr/recognition', formdata, options);
  }

  setCustIndiIdentity(params): Observable<any> {
    return this.http.put<any>(`${this.apiConfigBaseUrl}/api/v1/individual/SetCustIndiIdentity`, params, this.options);
  }

  setCustUser(params): Observable<any> {
    return this.http
      .post<any>(`${this.apiConfigBaseUrl}/api/v1/individual/SetCustUser`, params, this.options);
  }

  getCustIndiIdentity(queryParams): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/individual/GetCustIndiIdentity?` + queryParams, this.options);
  }

  setCustIndiFields(params): Observable<any> {
    return this.http.put<any>(`${this.apiConfigBaseUrl}/api/v1/individual/SetCustIndiFields`, params, this.options);
  }

  getAccountPage(queryParams): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/coreaccount/GetAccountPage?` + queryParams, this.options);
  }

  getCustMetas(queryParams): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/individual/GetCustMetas?` + queryParams, this.options);
  }

  deleteCustUser(params): Observable<any> {
    return this.http
      .delete<any>(`${this.apiConfigBaseUrl}/api/v1/individual/DeleteCustUser?` + params, this.options);
  }

  setCustIndiIdentityDefault(params) {
    return this.http.put<any>(`${this.apiConfigBaseUrl}/api/v1/individual/SetCustIndiIdentityDefault`, params, this.options);
  }

  delCustAddressContact(queryParams) {
    return this.http.delete<any>(`${this.apiConfigBaseUrl}/api/v1/individual/DelCustAddressContact?` + queryParams, this.options);
  }

  delCustIndiIdentity(queryParams) {
    return this.http.delete<any>(`${this.apiConfigBaseUrl}/api/v1/individual/DelCustIndiIdentity?` + queryParams, this.options);
  }

  setAccountStatus(params): Observable<any> {
    return this.http.put<any>(`${this.apiConfigBaseUrl}/api/v1/coreaccount/SetAccountStatus`, params, this.options);
  }

  getCustProfileUserPage(queryParams): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/individual/GetCustProfileUserPage?` + queryParams, this.options);
  }

  setProfileIdcardVerify(params): Observable<any> {
    return this.http.put<any>(`${this.apiConfigBaseUrl}/api/v1/coreuser/SetProfileIdcardVerify`, params, this.options);
  }

  setProfileInfo(params): Observable<any> {
    return this.http.put<any>(`${this.apiConfigBaseUrl}/api/v1/coreuser/SetProfileInfo`, params, this.options);
  }

  getProfileInfo(queryParams): Observable<any> {
    return this.http
      .get<any>(`${this.apiConfigBaseUrl}/api/v1/coreuser/GetProfileInfo?` + queryParams, this.options);
  }

  geAddressList(queryParams): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/corelogin/GeAddressList?` + queryParams, this.options);
  }

  setCustAddressContact(params): Observable<any> {
    return this.http.post<any>(`${this.apiConfigBaseUrl}/api/v1/individual/SetCustAddressContact`, params, this.options);
  }

  getAccountInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/coreaccount/GetAccountInfo?` + queryParams, this.options);
  }

  setAccountInfo(params): Observable<any> {
    return this.http.post<any>(`${this.apiConfigBaseUrl}/api/v1/coreaccount/SetAccountInfo`, params, this.options);
  }

  getProfileByReferral(queryParams): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/coreuser/GetProfileByReferral?` + queryParams, this.options);
  }

  getCustObjectListNew(queryParams): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/customer/GetCustObjectList?` + queryParams, this.options);
  }

  getCustObjectList(url: string, field_name: string): Observable<any> {
    const data: Observable<string[]> = of([]);
    return this.http.get(`${this.apiConfigBaseUrl}` + url, this.options).pipe(
      map((repon: any) => {
        return {key: field_name, result: repon.data};
      })
    );
  }

  exportReportLocalhost(url): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    });
  }

  // UIConfig

  getFormViewPage(queryParams): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/config/GetFormViewPage?` + queryParams, this.options);
  }

  delFormViewInfo(url, queryParams): Observable<any> {
    return this.http.delete(`${this.apiConfigBaseUrl}/api/v1/config/${url}?` + queryParams, this.options);
  }

  getGridViewPage(url, queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/config/${url}?` + queryParams, this.options);
  }

  getGroupInfo(queryParams: string): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/config/GetGroupInfo?` + queryParams, this.options);
  }

  setGroupInfo(queryParams: string): Observable<any> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/config/SetGroupInfo`, queryParams, this.options);
  }

  setGridViewInfo(url, queryParams): Observable<any> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/config/${url}`, queryParams, this.options);
  }

  setFormViewInf(params): Observable<any> {
    return this.http.post<any>(`${this.apiConfigBaseUrl}/api/v1/config/SetFormViewInf`, params, this.options);
  }

  delGridViewInfo(queryParams): Observable<any> {
    return this.http.delete<any>(`${this.apiConfigBaseUrl}/api/v1/config/DelGridViewInfo?` + queryParams, this.options);
  }

  // apiCoreBaseInvest
  getUsers(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/UniUser/getUsers?` + queryParams, this.options);
  }

  delUser(queryParams): Observable<any> {
    return this.http.delete(`${this.apiConfigBaseUrl}/api/v1/UniUser/DelUser?` + queryParams, this.options);
  }

  addUser(queryParams): Observable<any> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/UniUser/AddUser`, queryParams, this.options);
  }

  updateUser(id, queryParams): Observable<any> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/UniUser/UpdateUser?userId=${id}`, queryParams, this.options);
  }

  getUserById(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/UniUser/GetUserById?` + queryParams, this.options);
  }

  resetUserPassword(queryParams): Observable<any> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/UniUser/ResetUserPassword?` + queryParams, null, this.options);
  }

  lockUser(data): Observable<any> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/ubonduser/LockUser`, data, this.options);
  }

  unLockUser(data): Observable<any> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/ubonduser/UnLockUser`, data, this.options);
  }

  setUserStatus(data): Observable<any> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/UniUser/SetUserStatus?` + data, null, this.options);
  }

  // UserRole

  getUserRolePage(params): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/userrole/GetUserRolePage?` + params, this.options);
  }

  getUserRoleInfo(queryParams): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/userrole/GetUserRoleInfo?` + queryParams, this.options);
  }

  setUserRoleDraft(queryParams): Observable<any> {
    return this.http.post<any>(`${this.apiConfigBaseUrl}/api/v1/userrole/SetUserRoleDraft`, queryParams, this.options);
  }
  setUserRoleInfo(queryParams): Observable<any> {
    return this.http.post<any>(`${this.apiConfigBaseUrl}/api/v1/userrole/SetUserRoleInfo`, queryParams, this.options);
  }

  delUserRoleInfo(params): Observable<any> {
    return this.http
      .delete<any>(`${this.apiConfigBaseUrl}/api/v1/userrole/DelUserRoleInfo?` + params, this.options);
  }

  setUserRoleClose(params): Observable<any> {
    return this.http
      .delete<any>(`${this.apiConfigBaseUrl}/api/v1/userrole/SetUserRoleClose?` + params, this.options);
  }

  setUserRoleSubmit(params): Observable<any> {
    return this.http
      .put<any>(`${this.apiConfigBaseUrl}/api/v1/userrole/SetUserRoleSubmit`, params, this.options);
  }

  getCustObjectListV2(url, field_name): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}` + url, this.options).pipe(
      map((repon: any) => {
        if (repon.status === 'success' && repon.data && repon.data.length > 0) {
          return {
            key: field_name, result: repon.data.map(item => {
              return {
                label: item.name,
                name: item.name,
                code: item.value,
                ...item
              };
            })
          };
        } else {
          return {key: field_name, result: []};
        }

      }), catchError(error => {
        return throwError('Capital not found!');
      })
    );
  }

  getCustObjectListTreeV2(url, field_name): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}` + url, this.options).pipe(
      map((repon: any) => {
        return { key: field_name, result: repon.data };
      }), catchError(error => {
        return of(error.error);
      })
    );
  }

  dashBoardGet(): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/config/DashBoardGet`, this.options);
  }

  getTimeLinePage(): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/config/GetTimeLinePage`, this.options);
  }

  getUserRolePageByWeb(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/userrole/GetUserRolePageByWeb?` + queryParams, this.options);
  }

  setUserRoleImport(queryParams): Observable<any> {
    const customOptions = {
      headers: new HttpHeaders({
        Authorization: this.authService.getAuthorizationHeaderValue()
      })
    };
    return this.http.post<any>(`${this.apiConfigBaseUrl}/api/v1/userrole/SetUserRoleImport`, queryParams, customOptions);
  }

  setUserRoleAccept(queryParams): Observable<any> {
    return this.http.post<any>(`${this.apiConfigBaseUrl}/api/v1/userrole/SetUserRoleAccept`, queryParams, this.options);
  }

  setUserRoleExportDraft(params): Observable<Blob> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/userrole/SetUserRoleExportDraft`, params, {
      headers: new HttpHeaders({
        Authorization: this.authService.getAuthorizationHeaderValue(),
      }),
      responseType: 'blob'
    });
  }

  getUserRoleImportTemp(): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/userrole/GetUserRoleImportTemp`, {
      headers: new HttpHeaders({
        Authorization: this.authService.getAuthorizationHeaderValue(),
      }),
      responseType: 'blob'
    });
  }

  getUserRoleImportPage(queryParams): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/userrole/GetUserRoleImportPage?${queryParams}`, this.options);
  }

  delUserRoleImport(queryParams): Observable<any> {
    return this.http.delete<any>(`${this.apiConfigBaseUrl}/api/v1/userrole/DelUserRoleImport?${queryParams}`, this.options);
  }

  getMenuTrees(queryParams): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/webmanager/GetWebMenuTrees?${queryParams}`, this.options);
  }

  getClientMenuPageByParentId(queryParams): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/webmenu/GetClientMenuPageByParentId?${queryParams}`, this.options);
  }

  getMenuActionTrees(queryParams): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/webmenu/GetMenuActionTrees?${queryParams}`, this.options);
  }

  getWebManagements(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/webmanager/GetWebManagements?` + queryParams, this.options);
  }

  // Import người dùng


  setUserImport(queryParams): Observable<any> {
    const customOptions = {
      headers: new HttpHeaders({
        Authorization: this.authService.getAuthorizationHeaderValue()
      })
    };
    return this.http.post<any>(`${this.apiConfigBaseUrl}/api/v1/usermanage/SetUserImport`, queryParams, customOptions);
  }

  setUserImportAccept(queryParams): Observable<any> {
    return this.http.post<any>(`${this.apiConfigBaseUrl}/api/v1/usermanage/SetUserImportAccept`, queryParams, this.options);
  }

  setUserExportDraft(params): Observable<Blob> {
    return this.http.post(`${this.apiConfigBaseUrl}/api/v1/usermanage/SetUserExportDraft`, params, {
      headers: new HttpHeaders({
        Authorization: this.authService.getAuthorizationHeaderValue(),
      }),
      responseType: 'blob'
    });
  }

  getUserImportTemp(): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/usermanage/GetUserImportTemp`, {
      headers: new HttpHeaders({
        Authorization: this.authService.getAuthorizationHeaderValue(),
      }),
      responseType: 'blob'
    });
  }

  setUserExport(params): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/usermanage/SetUserExport?` + params, {
      headers: new HttpHeaders({
        Authorization: this.authService.getAuthorizationHeaderValue(),
      }),
      responseType: 'blob'
    });
  }

  getUserImportPage(queryParams): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/usermanage/GetUserImportPage?${queryParams}`, this.options);
  }

  delUserImport(queryParams): Observable<any> {
    return this.http.delete<any>(`${this.apiConfigBaseUrl}/api/v1/usermanage/DelUserImport?${queryParams}`, this.options);
  }

  getAuditlogPage(queryParams): Observable<any> {
    return this.http.get(`${this.apiConfigBaseUrl}/api/v1/auditlog/GetPage?` + queryParams, this.options);
  }

  getWebMenuListByUserId(userId, webId): Observable<any> {
    return this.http.get<any>(`${this.apiConfigBaseUrl}/api/v1/webmanager/GetWebMenuListByUserId?` +
      `userId=${userId}&webId=${webId}`, this.options);
  }
}
