import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CurrencyFormatPipe } from '../common/pipe/currency-format.pipe';
import {MenuModule} from 'primeng/menu';
import { ButtonAgGridComponent } from '../common/ag-component/button-renderermutibuttons.component';
import {ButtonModule} from 'primeng/button';
import { AvatarFullComponent } from '../common/ag-component/avatarFull.component';
import {RatingModule} from 'primeng/rating';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonRendererComponent } from '../common/ag-component/button-renderer.component';

@NgModule({
    exports: [
        CurrencyFormatPipe,
        ButtonAgGridComponent,
        ButtonRendererComponent,
        AvatarFullComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        MenuModule,
        ButtonModule,
        RatingModule,
        SplitButtonModule

    ],
    declarations: [
        CurrencyFormatPipe,
        ButtonAgGridComponent,
        ButtonRendererComponent,
        AvatarFullComponent,
    ],
    entryComponents: [

    ]
})
export class SharedCommonModule { }
