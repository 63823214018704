import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ListGridAngularComponent } from './list-grid-angular.component';
import { CustomTooltipComponent } from '../ag-component/customtooltip.component';
import { ButtonAgGridComponent } from '../ag-component/button-renderermutibuttons.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { AvatarFullComponent } from '../ag-component/avatarFull.component';
import { DialogModule } from 'primeng/dialog';
import { ratingAgGridComponent } from '../ag-component/rating.component';
import { RatingModule } from 'primeng/rating';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonRendererComponent } from '../ag-component/button-renderer.component';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
    declarations: [ListGridAngularComponent, ratingAgGridComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        RatingModule,
        DialogModule,
        ButtonModule,
        SplitButtonModule,
        TranslateModule,
        AgGridModule.withComponents([
            CustomTooltipComponent,
            ButtonAgGridComponent,
            AvatarFullComponent,
            ratingAgGridComponent,
            ButtonRendererComponent
          ]),
    ],
    entryComponents: [],
    exports: [
        ListGridAngularComponent
    ],
    providers: []
})
export class ListGridAngularModule {}