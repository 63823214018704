<p-toolbar>
  <div class="d-flex bet">
    <div class="p-toolbar-group-left col-8 pt-0 pb-0">
      <div class=" d-flex gap20 middle">
        <a (click)="goToHome()" class="go-to-home d-flex middle gap8">
          <span class="icon">
            <span class="uni uni-role"></span>
          </span>
          Phân quyền
        </a>
        <svg width="2" height="19" viewBox="0 0 2 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect y="0.5" width="2" height="18" fill="#D9D9D9"/>
        </svg>
        <app-sidebar></app-sidebar>
      </div>
    </div>
    <div class="col-4">
      <div class="flex gap12 end col-6 middle p-0 justify-content-end" style="
      width: 100%;
  ">
        <div class="p-toolbar-group-right profile">
            <div class="card flex align-items-center">
              <span class="item flag d-flex middle gap6" (click)="opLanguage.toggle($event)">
                <i class="uni-icon uni-language"></i>
              </span>
              <span class="noti item">
                <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 19.75C9.1 19.75 10 18.85 10 17.75H6C6 18.85 6.89 19.75 8 19.75ZM14 13.75V8.75C14 5.68 12.36 3.11 9.5 2.43V0.25H6.5V2.43C3.63 3.11 2 5.67 2 8.75V13.75L0 15.75V16.75H16V15.75L14 13.75Z" fill="#212633"/>
                </svg>
              </span>
              <span class="item">
                <span (click)="optionTopbar.toggle($event)">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 16H16V12H12M12 10H16V6H12M6 4H10V0H6M12 4H16V0H12M6 10H10V6H6M0 10H4V6H0M0 16H4V12H0M6 16H10V12H6M0 4H4V0H0V4Z" fill="#212633"/>
                  </svg>
                </span>
              </span>
              <p-avatar  image="../../../assets/images/walter.png" size="large"></p-avatar>
              <span  class="ml-12 mr-15 name" (click)="menu.toggle($event)"> {{userName}} &nbsp;
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.59 0.294922L6 4.87492L1.41 0.294922L0 1.70492L6 7.70492L12 1.70492L10.59 0.294922Z" fill="#212633" />
                </svg>
              </span>
              <p-menu #menu [popup]="true" [model]="items" styleClass="my-account-menu"></p-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-toolbar>

<p-overlayPanel #optionTopbar styleClass="option-topbar">
  <div class="grid" *ngIf="cats && cats.length > 0">
    <div class="item" *ngFor="let cat of cats">
      <a href="{{ cat.webUrl }}">
        <div class="in">
            <p-button [label]="cat.webName" styleClass="p-button-sm btn-white-image" >
             <span class="icon">
                    <i class="{{cat.iconClass ? cat.iconClass : 'uni uni-overview'}}"></i>
                </span>
            </p-button>
        </div>
      </a>
    </div>
  </div>
</p-overlayPanel>



<p-overlayPanel #opLanguage styleClass="choose-language" [style]="{'z-index': 99999}">
  <ng-template pTemplate="content">
    <h3>{{"select_language" | translate}}</h3>
    <ul class="list-lang">
      <li class="active" [ngClass]="{'active': translate.currentLang === 'vi'}" (click)="translate.use('vi');opLanguage.toggle($event);saveLang('vi')">
        <div class="d-flex middle gap-10">
          <span class="uni-icon uni-flag-vn"></span>
          <span>{{"vietnamese" | translate}}</span>
          <span class="checked" *ngIf="translate.currentLang === 'vi'">
                <i class="uni-icon uni-vector uni-blue-color"></i>
              </span>
        </div>
      </li>
      <li (click)="translate.use('en');opLanguage.toggle($event);saveLang('en')" [ngClass]="{'active': translate.currentLang === 'en'}">
        <div class="d-flex middle gap-10">
          <span class="uni-icon uni-flag-en"></span>
          <span>{{"english" | translate}}</span>
          <span class="checked" *ngIf="translate.currentLang === 'en'">
                <i class="uni-icon uni-vector uni-blue-color"></i>
              </span>
        </div>
      </li>
      <li (click)="translate.use('cn');opLanguage.toggle($event);saveLang('cn')" [ngClass]="{'active': translate.currentLang === 'cn'}">
        <div class="d-flex middle gap-10">
          <!-- <span class="uni-icon uni-flag-en"></span> -->
          <span class="flag-img"><img src="../../../assets/images/chinase.png"></span>
          <span>{{"_Trung Quốc_" | translate}}</span>
          <span class="checked" *ngIf="translate.currentLang === 'cn'">
                <i class="uni-icon uni-vector uni-blue-color"></i>
              </span>
        </div>
      </li>
    </ul>
  </ng-template>
</p-overlayPanel>
