import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'child-rating',
  template: `
    <div class="avatar-wrap"> 
        <p-rating [ngModel]="value" [readonly]="true" [stars]="5" [cancel]="false"></p-rating>
    </div>
    `
})
export class ratingAgGridComponent implements OnInit {
  value: number
  constructor() { }

  agInit(params: any) {
    this.value = parseInt(params.value);
  }
  ngOnInit() {
  }

}
